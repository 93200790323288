@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@media only screen and (max-width: 600px) {
    .leaflet-container {
        font-family: inherit !important;
    }

    .maplayers {
        width: 90%;
      }
}

.hyper-links,
.viewerCell,
.announcements,
.reportCell {
  text-decoration: none;
}


@media (prefers-color-scheme: light) {
    body {
        color: silver
    }

    .history-header {
        color: #00549f
    }

    .annc-header,
    .styledtablerow {
        background-color: #f0f7fb;
    }

    a.hyper-links
    a.viewerCell,
    .reportCell {
        color: #00549f !important;
    }

    a.hyper-links:hover,
    a.viewerCell:hover,
    a.reportCell:hover {
        color: rgba(0, 111, 210, 0.8) !important;
    }

    .link-icon {
        color: #00549f !important;
        vertical-align: bottom;
        padding-left: 2px;
    }

    a.map-link {
        color: #00549F;
    }

    a.map-link:hover {
        color: rgba(0, 111, 210, 0.8) !important;
    }

    .separator {
        color: rgba(0, 111, 210, 0.8) !important;
        font-weight: bold;
    }

    .over {
        color: rgba(255, 0, 0, 1) !important;
        font-weight: bold !important;
    }

    .report-header {
        background-color: rgba(125, 154, 170, 0.5);
        border-radius: 2px;
        width: 100%;
        margin: auto;
      }

      .hover-highlight:hover {
        -webkit-backdrop-filter: invert(50%);
        backdrop-filter: invert(50%);
        color: white;
        border-radius: 2px;
      }

      .dragHandle-icon {
        color: rgba(207, 212, 214, 0.5) !important;
        vertical-align: top;
        padding-left: 2px;
      }
}

@media (prefers-color-scheme: dark) {
    body {
        color: white
    }

    .history-header {
        color: #f0f7fb
    }

    .leaflet-popup-tip {
        background-color: black !important;
        color: white;
        box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    }

    .annc-header,
    .styledtablerow {
        background-color: #303132;
    }

    a.hyper-links,
    a.viewerCell,
    .announcements a,
    .reportCell {
        /* color: #f0f7fb !important; */
        color : #58a6ff !important;

    }

    a.hyper-links:hover,
    a.viewerCell:hover,
    .announcements a:hover,
    a.reportCell:hover {
        color: rgba(207, 212, 214, 0.5) !important;
    }

    .link-icon {
        color: rgba(207, 212, 214, 0.5) !important;
        vertical-align: bottom;
        padding-left: 2px;
    }

    a.map-link {
        color: #f0f7fb !important;
    }

    a.map-link:hover {
        color: rgba(207, 212, 214, 0.5) !important;
    }

    .separator {
        color: rgba(207, 212, 214, 0.5) !important;
        font-weight: bold;
    }

    .over {
        color: rgba(251, 67, 105, 1) !important;
        font-weight: bold !important;
    }

    .report-header {
        background-color: rgba(125, 154, 170, 0.5);
        border-radius: 2px;
        width: 100%;
        margin: auto;
      }

      .hover-highlight:hover {
        -webkit-backdrop-filter: invert(100%);
        backdrop-filter: invert(100%);
        color: blue;
        border-radius: 2px;
      }

      .dragHandle-icon {
        color: rgba(207, 212, 214, 0.5) !important;
        vertical-align: top;
        padding-left: 2px;
    }
}