@font-face {
  font-family: "HighwayGothic";
  src: local("HighwayGothic"),
    url("./fonts/highwayGothic/HWYGOTH.woff") format("woff");
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: inherit !important;
  font-size: 14px !important;
}

.tt-popup {
  min-width: 300px;
}

.highTravelTime {
  color: red;
  font-weight: bold;
  font-size: larger;
}

.marqueeWrapper {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.marquee {
  color: white;
  font-weight: bold;
  font-size: x-large;
}

.marquee-warning {
  background-color: #ff0000;
}

.leaflet-popup-content-wrapper {
  background-color: transparent !important;
}

.leaflet-popup-content {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  min-width: 300px !important;
}

a.motdLink {
  color: gray;
}

.maplayers {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  padding: 10px;
  width: 400px;
}

.maplayers-container {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x:hidden;
}

.alert {
  margin: 0px;
  border-bottom: 1px solid silver;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #f0f7fb;
  /* background-color:  #f0f7fb; */
}

.alerteven {
  margin: 0px;
  /* border-bottom: 1px solid silver; */
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #f0f7fb;
}

.alertodd {
  margin: 0px;
  /* border-bottom: 1px solid silver; */
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #383b3c;
  /* background-color:  #f0f7fb; */
}

a .alert {
  color: #f0f7fb;
}

.layer-title {
  font-size: large;
  color: #0098DB;
}


.layer-title-description {
  font-family: Segoe UI;
  font-size: 10px;
}

.cluster-camera {
  background-image: url("../public/mapicons/Camera/Camera_Cluster_v16.png");
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  background-size: contain;
  color: black;
}

.grabber {
  height: 20px;
  background-color: rgba(192, 192, 192, 0.8);
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  cursor: pointer;
}

.cluster-construction {
  background-image: url("../public/mapicons/Construction/Construction_Cluster_v16.png");
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  background-size: contain;
  color: black;
}

.cluster-dms {
  background-image: url("../public/mapicons/Dms/DMS_Cluster_C_v17.png");
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  background-size: contain;
  color: black;
}

.tt-icon {
  color: white;
}

/*
* imported from styles.js
*/
.tablerow {
  font-size: 100pt;
}

.tablecellnoborder {
  border: 0px !important;
  border-spacing: 0px;
  border-collapse: separate;
}


.layertablecell {
  border-spacing: 0px;
  border-collapse: separate;
  font-family: Arial !important;
  font-size: 12px !important;
  border: 0px;
  padding-left: 5px !important;
  padding-right: 1px !important;

  border: 0px !important;
}

.layertablecellRight {
  border-spacing: 0px;
  border-collapse: separate;
  border: 0px;
  padding-right: 1px !important;
  padding-left: 5px !important;
  border-bottom: 0px !important;
  font-family: Arial !important;
  font-size: 12px !important;
}

.layertablecellLeft {
  border-spacing: 0px;
  border-collapse: separate;
  border: 0px;
  padding-left: 5px !important;
  padding-right: 2px !important;
  border-bottom: 0px !important;
  font-family: Arial !important;
  font-size: 12px !important;
}

.layertablerightcell {
  font-size: 10px;
  border-spacing: 0px;
  border-collapse: separate;
  font-family: 'Segoe UI';
}

.popupCard {
  min-width: 400px;
  width: 100%;
}

.popupCardMobile {
  width: 100% !important;
  overflow: hidden;
}

a.reportCell {
  text-decoration: none;
}

.side-menu .active {
  background-color: rgba(125, 154, 170, 0.5);
  border-radius: 0px 5px 5px 0px;
}

.map-link {
  font-size: 15px;
  padding-left: 10px;
  text-decoration: none;
}

.separator {
  display: inline-block;
  margin-right: 1em;
  margin-left: 1em;
}

.uncongested {
  background-color: rgba(0, 106, 0, 0.5);
}

.light {
  background-color: rgba(51, 255, 0, 0.5);
}

.medium {
  background-color: rgba(255, 255, 0, 0.5);
}

.heavy {
  background-color: rgba(255, 0, 0, 0.5);
}

.unknown {
  background-color: rgba(103, 103, 103, 0.5);
}

.MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}

tr.quick-tt-row td:first-child {
  max-width: 50% !important;
  width: 50% !important;
}

.mm-icon {
  height:20px;
  width:20px;
}

.leaflet-display-zoom  {
  background-color: white;
  color: black;
  width: 30px;
  height: 30px;
  line-height: 25px;
  text-align: center;
}

.trucker-icon {
  height:20px;
  margin-right:.5rem;
}

.hash-link {
  padding: 1rem;
}

.trip-iframe {
  width:100%;
  height:99%;
  overflow:hidden;
  border: none;
  margin-top:2px;
  background-color: white;
}

.map-controls-header {
  background-color: rgba(192, 192, 192, 0.8) !important;
  min-height: 10px !important;
}

.video-player {
  width: fit-content;
  margin: auto;
  width: 100%;
  height:100%;
  text-align: center;
  margin-top: 20px;
}

.hover-highlight {
  display: inline-block;
  font-weight: bold;
}

.video-report {
  height: calc(100vh - 250px);
  width: calc(100% - 300px);
  margin: auto;
}

.video-popup {
  height:325px;
}


@media only screen and (max-width: 600px) {
  .video-report {
    height:initial;
    width: initial;
    margin: auto;
  }

  .video-popup {
    height: initial;
    width:initial;
  }
}

.reset-control {
  cursor: pointer;
}


.scroll-to-top {
  position: fixed;
  bottom: 2%;
  right: 2%;
}

.cluster-transitstop {
  background-image: url("../public/mapicons/TransitStations/TrainStation.png");
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  background-size: contain;
  color: black;
}
